<template>
  <div class="hold-transition bg-frontera">
    <div class="container">
      <loading v-if="cargando" />
      <div class="wrapper" v-if="preguntas[0]">
        <section class="container-fluid" style="height: 700px">
          <fieldset class="well card my-custom-scrollbar">
            <legend class="well-legend text-bold bg-info text-light">
              <div class="text-center justify-content-center">
                Competencias
                <span id="temp" class="text-white"></span>
              </div>
            </legend>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#modal-material"
                          >
                            <!-- <i class="fas fa-sync-alt"></i> -->
                            <i class="fas fa-folder-open"> Material</i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group col-md-12"
                    v-for="(pregunta, index) in preguntas"
                    :key="pregunta.data"
                  >
                    <label
                      for="numero_documento"
                      class="display-6 text-uppercase"
                      :class="
                        selecciones.preguntas.find(
                          (pregun) => pregun.pregunta_id === pregunta.id
                        )
                          ? 'display-6 text-uppercase text-success'
                          : 'display-6 text-uppercase text-danger'
                      "
                      >Pregunta # {{ index + 1 }}</label
                    >
                    <p>{{ pregunta.descripcion }}</p>
                    <div class="container-sm">
                      <table
                        class="table table-stripped table-hover table-bordered table-sm rounded"
                      >
                        <tbody>
                          <tr
                            v-for="respuesta in pregunta.respuestas"
                            :key="respuesta.data"
                          >
                            <td
                              @click="
                                selectRespuesta(
                                  pregunta.id,
                                  respuesta.id,
                                  respuesta.estado
                                )
                              "
                              :class="
                                selecciones.preguntas.find(
                                  (pregun) =>
                                    pregun.pregunta_id === pregunta.id &&
                                    pregun.respuesta_id === respuesta.id
                                )
                                  ? 'bg-info text-uppercase font-weight-bold text-center rounded'
                                  : 'font-weight-bold text-gray'
                              "
                            >
                              <input
                                type="radio"
                                v-if="
                                  !selecciones.preguntas.find(
                                    (pregun) =>
                                      pregun.pregunta_id === pregunta.id &&
                                      pregun.respuesta_id === respuesta.id
                                  )
                                "
                              />
                              {{ respuesta.descripcion }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <div class="justify-content-between text-center">
                      <button
                        v-show="completo"
                        type="button"
                        class="btn bg-primary"
                        @click="save"
                      >
                        <i class="fas fa-paper-plane"></i><br />Envíar
                        Respuestas
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="modal fade" id="modal-material">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header bg-primary pt-2 pb-2">
                  <h4 class="modal-title text-white">Material Evalaución</h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeModal"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="card">
                      <div class="card-body table-responsive p-0">
                        <table
                          style="font-size: 0.85em"
                          class="table table-bordered table-striped table-hover table-sm text-nowrap"
                        >
                          <thead class="text-center">
                            <tr>
                              <th>Tipo</th>
                              <th>Descripción</th>
                              <th>Link</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="material in materiales"
                              :key="material.data"
                            >
                              <td class="text-center">
                                {{
                                  material.tipo == 1
                                    ? "Pdf"
                                    : material.tipo == 2
                                    ? "Video"
                                    : material.tipo == 3
                                    ? "Imagen"
                                    : null
                                }}
                              </td>
                              <td>{{ material.descripcion }}</td>
                              <td>
                                <div class="btn-group float-right">
                                  <a
                                    target="_blank"
                                    :href="uri_docs + material.link"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                  >
                                    <i class="fas fa-file"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading";
import moment from "moment";

export default {
  name: "EvaluacionConductor",
  components: { Loading },
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      tiempo: {
        actual: moment(),
        fecha_fin: null,
        fecha_ini: null,
        promedio: null,
      },
      cargando: false,
      preguntas: [],
      solicitud_id: null,
      evaluacionCond_id: null,
      evaluacion_id: null,
      intento: null,
      solicitud: {
        fecha_ini: null,
        fecha_fin: null,
      },
      materiales: [],
      completo: false,
      selecciones: {
        preguntas: [],
      },
    };
  },

  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    
    init() {
      this.cargando = true;
      this.completo = false;
      this.intento = this.$route.params.intento;
      this.solicitud_id = this.$route.params.solicitud_id;
      this.solicitud.fecha_ini = this.$route.params.fecha_ini;
      this.solicitud.fecha_fin = this.$route.params.fecha_fin;
      this.evaluacionCond_id = this.$route.params.evaluacionCond_id;
      this.evaluacion_id = this.$route.params.evaluacion_id;
      this.materiales = this.$route.params.materiales;
      this.token = this.$route.params.token;
      clearInterval(this.iniciarTemporalizador);
      if (!this.solicitud.fecha_fin) {
        setInterval(this.iniciarTemporalizador, 10);
      }

      if (this.solicitud_id) {
        axios
          .get("api/Evaluaciones/PreguntasRespuestas", {
            params: { solicitud_id: this.solicitud_id },
          })
          .then((response) => {
            this.preguntas = response.data;

            if (response.data[0]) {
              this.preguntas = response.data;
            } else {
              this.preguntas = [];
              this.$swal({
                title: "Evaluación Finalizada:",
                text: "Su puntaje fue del: " + response.data + "%",
                icon: "success",
                confirmButtonText: "Ok!",
              }).then((result) => {
                return this.$router.push({
                  name: "MaterialEvaluacion",
                  params: { id: this.solicitud_id, tok: this.token },
                });
              });
            }
          });
      }

      this.cargando = false;
    },

    iniciarTemporalizador() {
      this.tiempo.temp = document.getElementById("temp");
      this.tiempo.actual = moment();
      this.tiempo.fecha_fin = moment(this.solicitud.fecha_fin);
      this.tiempo.fecha_ini = moment(this.solicitud.fecha_ini);
      this.tiempo.promedio = this.tiempo.actual.diff(this.tiempo.fecha_ini);
      if (this.solicitud.fecha_fin && this.solicitud.fecha_ini) {
        this.tiempo.promedio = this.tiempo.fecha_fin.diff(
          this.tiempo.fecha_ini
        );
      }
      function menorDiez(e) {
        return (e < 10 ? "0" : "") + e;
      }
      this.tiempo.ms = this.tiempo.promedio % 1000;
      this.tiempo.promedio = (this.tiempo.promedio - this.tiempo.ms) / 1000;
      this.tiempo.segundos = this.tiempo.promedio % 60;
      this.tiempo.promedio = (this.tiempo.promedio - this.tiempo.segundos) / 60;
      this.tiempo.minutos = this.tiempo.promedio % 60;
      this.tiempo.horas = (this.tiempo.promedio - this.tiempo.minutos) / 60;
      if (this.tiempo.temp != null) {
        this.tiempo.temp.innerHTML =
          menorDiez(this.tiempo.horas) +
          ":" +
          menorDiez(this.tiempo.minutos) +
          ":" +
          menorDiez(this.tiempo.segundos);
      }
      if (this.solicitud.fecha_fin) {
        clearInterval(this.iniciarTemporalizador);
      }
    },

    selectRespuesta(preg, resp, estado) {
      let totalPreguntas = this.preguntas.length;
      let preguntaExistente = this.selecciones.preguntas.findIndex(function (
        pregunta
      ) {
        return pregunta.pregunta_id === preg;
      });

      if (preguntaExistente !== -1) {
        this.selecciones.preguntas[preguntaExistente].respuesta_id = resp;
        this.selecciones.preguntas[preguntaExistente].respuesta_estado = estado;
      } else {
        this.selecciones.preguntas.push({
          pregunta_id: preg,
          respuesta_id: resp,
          respuesta_estado: estado,
        });
      }
      if (totalPreguntas == this.selecciones.preguntas.length) {
        this.completo = true;
      }
    },
    save() {
      let datos = {
        respuestas: this.selecciones.preguntas,
        fecha_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
        solicitud_id: this.solicitud_id,
        evaluacionCond_id: this.evaluacionCond_id,
        evaluacion_id: this.evaluacion_id,
        intento: this.intento,
      };
      axios
        .post("api/Evaluaciones/DetEvaluaciones", datos)
        .then((response) => {
          let puntaje_total = response.data;
          this.selecciones.preguntas = [];
          clearInterval(this.iniciarTemporalizador);
          this.init();
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  mounted() {
    this.init();
    this.toggleBodyClass("addClass", "sidebar-collapse");
    this.toggleBodyClass("removeClass", "sidebar-mini");
  },

  destroyed() {
    this.toggleBodyClass("removeClass", "sidebar-collapse");
    this.toggleBodyClass("addClass", "sidebar-mini");
  },
};
</script>

<style>

.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgb(248, 8, 8);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgb(248, 8, 8), 0 0 20px rgb(248, 8, 8);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 3px solid #09cce6;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  border: 2px solid #09cce6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}

.my-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
  scrollbar-color: rgb(13, 157, 206) rgb(255, 255, 255);
}

/* .table-wrapper-scroll-y {
  display: block;
} */
</style>
